.addForm {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: stretch;
}

.formBox {
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.label {
  font-weight: bold;
}

.input,
.textarea {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}
