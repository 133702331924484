header {
  background: #6200ee;
  padding: 12px;
  color: white;
  display: flex;
  justify-content: space-between;
}
header h1 {
  font-size: 20px;
  font-weight: 200;
}

.search {
  display: flex;
}
.search input {
  width: 13em;
  margin-right: 5px;
}
