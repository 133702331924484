.button {
  border: 0;
  background: #2a913e;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5em 2.5em;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
}
